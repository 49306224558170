<template>
  <div>
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th colspan="3" />
          <th colspan="4">
            Job Status
          </th>
        </tr>
        <tr>
          <th />
          <th>Client</th>
          <th>Progress Bytes</th>
          <th>Succeded</th>
          <th>Active</th>
          <th>Warning</th>
          <th>Failed</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(data, dataIndex) in filteredAndSortedJobsByClient">
          <tr :key="`${dataIndex}`">
            <td>
              <feather-icon
                :icon="data.showDetails === true ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                class="cursor-pointer float-left"
                @click="toggleDetails(data)"
              />
              <JobStatusIcon :status="data.agg.maxStatus" />
            </td>
            <td>
              <b-link :to="{ name: 'avamar-detail.client-detail', params: { clientName: data.client } }">
                {{ data.client }}
              </b-link>
            </td>
            <td>{{ data.agg.progressBytes | prettyBytes }}</td>
            <td :class="{'table-success': data.agg.maxStatus === 0}">
              {{ data.agg.succeededJobCount }}
            </td>
            <td :class="{'table-info': data.agg.maxStatus === 10}">
              {{ data.agg.activeJobCount }}
            </td>
            <td :class="{'table-warning': data.agg.maxStatus === 20}">
              {{ data.agg.warningJobCount }}
            </td>
            <td :class="{'table-danger': data.agg.maxStatus === 30}">
              {{ data.agg.failedJobCount }}
            </td>
          </tr>
          <tr
            v-if="data.showDetails"
            :key="`${dataIndex}_details`"
          >
            <td
              colspan="7"
              class="pb-2 pt-2"
            >
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th />
                    <th>Start</th>
                    <th>Elapsed</th>
                    <th>New Bytes</th>
                    <th>Progress Bytes</th>
                    <th>Plugin</th>
                    <th>Status</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(job, jobIndex) in data.jobs"
                  >
                    <tr
                      :key="`${dataIndex}_${jobIndex}`"
                    >
                      <td>
                        <feather-icon
                          :icon="job.showDetails === true ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                          class="cursor-pointer float-left"
                          @click="toggleDetails(job)"
                        />
                        <JobStatusIcon :status="job.status" />
                      </td>
                      <td>{{ job.startTime | formatDateTimeTz(timezone) }}</td>
                      <td>{{ job.elapsed | formatClrTimeSpan }}</td>
                      <td>{{ job.newBytesPercentage }}%</td>
                      <td>{{ job.progressBytes | prettyBytes }}</td>
                      <td>{{ job.plugin }}</td>
                      <td>{{ job.statusText }}</td>
                      <td>{{ job.typeText }}</td>
                    </tr>
                    <tr
                      v-if="job.showDetails"
                      :key="`${dataIndex}_${jobIndex}_details`"
                    >
                      <td colspan="8">
                        <div class="row p-2">
                          <div class="col">
                            <dl>
                              <dt>Job Id</dt>
                              <dd>{{ job.jobId }}</dd>

                              <dt>Error Code</dt>
                              <dd>{{ job.errorCode }}</dd>

                              <dt>Start</dt>
                              <dd>{{ job.startTime | formatDateTimeTz(timezone) }}</dd>

                              <dt>End</dt>
                              <dd>{{ job.endTime | formatDateTimeTz(timezone) }}</dd>

                              <dt>Scheduled Start</dt>
                              <dd>{{ job.scheduledStart | formatDateTimeTz(timezone) }}</dd>

                              <dt>Scheduled End</dt>
                              <dd>{{ job.scheduledEnd | formatDateTimeTz(timezone) }}</dd>
                            </dl>
                          </div>
                          <div class="col">
                            <dl>
                              <dt>Elapsed Wait</dt>
                              <dd>{{ job.elapsedWait | formatClrTimeSpan }}</dd>

                              <dt>Elapsed</dt>
                              <dd>{{ job.elapsed | formatClrTimeSpan }}</dd>

                              <dt>Progress Bytes</dt>
                              <dd>{{ job.progressBytes | prettyBytes }}</dd>

                              <dt>New Bytes</dt>
                              <dd>{{ job.newBytesPercentage }}%</dd>
                            </dl>
                          </div>
                          <div class="col">
                            <dl>
                              <dt>Dataset</dt>
                              <dd>{{ job.dataset }}</dd>

                              <dt>Status</dt>
                              <dd>{{ job.statusText }}</dd>

                              <dt>Type</dt>
                              <dd>{{ job.typeText }}</dd>
                            </dl>
                          </div>
                          <div class="col">
                            <dl>
                              <dt>Container</dt>
                              <dd>{{ job.container }}</dd>

                              <dt>Retention Policy</dt>
                              <dd>{{ job.retentionPolicy }}</dd>

                              <dt>Domain</dt>
                              <dd>{{ job.domain }}</dd>

                              <dt>Plugin</dt>
                              <dd>{{ job.plugin }}</dd>

                              <dt>Wid</dt>
                              <dd>{{ job.wid }}</dd>
                            </dl>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<!-- eslint-disable no-param-reassign -->
<script>

import { BLink } from 'bootstrap-vue'
import JobStatusIcon from '@/views/asup/backup-software/components/JobStatusIcon.vue'

export default {
  components: {
    BLink,
    JobStatusIcon,
  },
  props: {
    jobs: {
      type: Array,
      required: true,
    },
    onlyErrors: {
      type: Boolean,
      default: false,
    },
    timezone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      jobsByClient: [],
    }
  },
  computed: {
    filteredAndSortedJobsByClient() {
      let result = this.jobsByClient
      if (this.onlyErrors === true) {
        result = this.jobsByClient.filter(x => x.agg.maxStatus !== 0)
      }

      return result.sort((a, b) => (a.client > b.client ? 1 : -1))
    },
  },
  watch: {
    jobs(jobs) {
      this.jobsByClient = this.groupByJobs(jobs)
    },
  },
  methods: {
    toggleDetails(data) {
      // eslint-disable-next-line no-param-reassign
      this.$set(data, 'showDetails', !data.showDetails)
      this.$forceUpdate() // Force render component (somehow the component did not render after changing showDetails for jobs...)
    },
    groupByJobs(jobs) {
      const groups = jobs.reduce((p, c) => {
      // eslint-disable-next-line no-param-reassign
        p[c.client] = p[c.client] || {
          jobs: [],
          agg: {
            progressBytes: 0,
            minStartTime: null,
            maxEndTime: null,
            maxStatus: 0,
            succeededJobCount: 0,
            activeJobCount: 0,
            warningJobCount: 0,
            failedJobCount: 0,
          },
        }

        c.showDetails = false
        p[c.client].jobs.push(c)
        p[c.client].agg.progressBytes += c.progressBytes
        p[c.client].agg.minStartTime = c.startTime < p[c.client].agg.minStartTime || p[c.client].agg.minStartTime == null ? c.startTime : p[c.client].agg.minStartTime
        p[c.client].agg.maxEndTime = c.endTime > p[c.client].agg.maxEndTime || p[c.client].agg.maxEndTime == null ? c.endTime : p[c.client].agg.maxEndTime
        p[c.client].agg.maxStatus = c.status > p[c.client].agg.maxStatus ? c.status : p[c.client].agg.maxStatus

        // eslint-disable-next-line default-case
        switch (c.status) {
          case 0:
            p[c.client].agg.succeededJobCount += 1
            break
          case 10:
            p[c.client].agg.activeJobCount += 1
            break
          case 20:
            p[c.client].agg.warningJobCount += 1
            break
          case 30:
            p[c.client].agg.failedJobCount += 1
            break
        }

        return p
      }, {})

      return Object.entries(groups).map(x => ({
        client: x[0],
        jobs: x[1].jobs.sort((a, b) => (a.startTime < b.startTime ? 1 : -1)),
        agg: x[1].agg,
        showDetails: x[1].agg.maxStatus !== 0,
      }))
    },
  },
}

</script>
