<template>
  <div>
    <b-card title="Policy Details">
      <b-row>
        <b-col>
          <table class="table table-sm table-borderless">
            <tbody>
              <tr>
                <th style="width: 220px">
                  Policy
                </th>
                <td>
                  {{ groupName }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days']"
            :max-selectable-days="90"
            :timezone="asup.timezone"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="onlyErrors"
            switch
          >
            Errors only
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card title="Clients with Backup jobs">
      <JobTableGroupedByClient
        :jobs="backupJobs"
        :only-errors="onlyErrors"
        :timezone="asup.timezone"
      />
    </b-card>

    <b-card title="Clients with Replication jobs">
      <JobTableGroupedByClient
        :jobs="replicationJobs"
        :only-errors="onlyErrors"
        :timezone="asup.timezone"
      />
    </b-card>

  </div>
</template>
<!-- eslint-disable no-param-reassign -->
<script>

import {
  BRow, BCol, BCard, VBPopover, BFormCheckbox, BForm, BFormGroup,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import JobTableGroupedByClient from './components/JobTableGroupedByClient.vue'

import moment from '@/libs/moment'
import AvamarService from '@/service/avamar.service'

export default {
  components: {
    BRow, BCol, BCard, BFormCheckbox, BForm, BFormGroup, DateRangePicker, JobTableGroupedByClient,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      client: {},
      timeRange: {
        range: 'last7Days',
      },
      onlyErrors: false,
      jobs: [],
    }
  },
  computed: {
    groupName() {
      return this.$route.params.jobGroup
    },
    backupJobs() {
      return this.jobs.filter(x => x.type === 0)
    },
    replicationJobs() {
      return this.jobs.filter(x => x.type === 1)
    },
  },
  beforeMount() {
    if (this.$route.query.from && this.$route.query.to) {
      this.timeRange = {
        startDate: moment(this.$route.query.from).toDate(),
        endDate: moment(this.$route.query.to).toDate(),
      }
    }

    if (this.$route.query.onlyErrors === true) {
      this.onlyErrors = true
    }

    // Watch properties after setting timer range from query to avoid loading data too many times
    this.$watch('timeRange', this.loadData)
    this.$watch('onlyErrors', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      AvamarService.getJobListAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        group: this.groupName,
      }, { disableTenantFilter: true })
        .then(result => {
          // automatically show details for failed jobs
          for (let i = 0; i < result.items.length; i += 1) {
            if (result.items[i].status === 30) {
            // eslint-disable-next-line no-param-reassign
              result.items[i].showDetails = true
            }
          }

          this.jobs = result.items
        })
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
